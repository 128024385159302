import 'reflect-metadata'
import React from 'react'
import ReactDOM from 'react-dom'
import 'index.css'
import App from 'App'
import 'components/Header/Header.sass'
import 'components/Page/Page.sass'
import 'styles/main.sass'
import 'pages/PriceList/priceList.sass'
import 'pages/Requests/requests.sass'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

ReactDOM.render(
  <>
    <App />
    <ToastContainer
      position="top-right"
      autoClose={10000}
      hideProgressBar={false}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
    />
  </>,
  document.getElementById('root')
)
