import React, { useState } from 'react'
import { Page } from 'components/Page/Page'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import { useQuery } from 'react-query'
import { useModules } from 'modules/ModuleFactory'
import { toast } from 'react-toastify'
import { Button, Icon } from '@material-ui/core'
import { IItemPriceList } from 'modules/priceList/services/priceList.service'
import CreateItemPriceList from './components/createItemPriceList'

export const itemPriceListDefault: IItemPriceList = {
  id: 0,
  name: '',
  priceFrom: 0,
  priceTo: 0,
  category: 0,
}

export default function PriceList(): JSX.Element {
  const { categoryModule } = useModules()
  const { priceListModule } = useModules()

  const [categoryId, setCategoryId] = useState('0')
  const [subCategoryId, setSubCategoryId] = useState('all')
  const [isEditMode, setEditMode] = useState(false)
  const [isCreateMode, setCreateMode] = useState(false)
  const [editItemPriceListIndex, setEditItemPriceListIndex] = useState<number | undefined>()
  const [editItemPriceList, setEditItemPriceList] = useState<IItemPriceList>(itemPriceListDefault)

  const { isLoading: isLoadingCategory, data: dataCategories } = useQuery(
    ['categories'],
    async () => {
      return (await categoryModule.getCategories()) || []
    }
  )

  const {
    isLoading,
    data: priceListData,
    refetch,
  } = useQuery(
    ['priceList', categoryId, subCategoryId],
    async () => {
      const newCategoryId = subCategoryId === 'all' ? categoryId : subCategoryId
      return (await priceListModule.getPriceListByCategoryId(Number(newCategoryId))) || []
    },
    {
      enabled: categoryId !== '0',
    }
  )

  const handleSubmitItemPriceList = async () => {
    const res = await priceListModule.saveItemPriceList(editItemPriceList)
    if (res) toast.success('Прайс лист успешно обновлён')
    setEditMode(false)
    setCreateMode(false)
    await refetch()
  }

  const handleDeleteItemPriceList = async () => {
    const res = await priceListModule.deleteItemPriceList(editItemPriceList)
    if (res) toast.success('Услуга успешно удалёна')
    setEditMode(false)
    setCreateMode(false)
    await refetch()
  }

  const handleChangeCategory = async (
    event: React.ChangeEvent<{ value: unknown; name?: string }>
  ) => {
    setCategoryId(event.target.value as string)
    setSubCategoryId('all')
  }

  const handleChangeSubCategory = async (
    event: React.ChangeEvent<{ value: unknown; name?: string }>
  ) => {
    setSubCategoryId(event.target.value as string)
  }

  const handleNameChange = (index: number, event: React.FormEvent<HTMLInputElement>): void => {
    setEditItemPriceList({ ...editItemPriceList, name: event.currentTarget.value })
  }

  const handlePriceFromChange = (index: number, event: React.FormEvent<HTMLInputElement>): void => {
    setEditItemPriceList({ ...editItemPriceList, priceFrom: Number(event.currentTarget.value) })
  }

  const handlePriceToChange = (index: number, event: React.FormEvent<HTMLInputElement>): void => {
    setEditItemPriceList({ ...editItemPriceList, priceTo: Number(event.currentTarget.value) })
  }

  return (
    <Page title={'Прайс листы'}>
      {!isLoadingCategory ? (
        <div className="priceListControl">
          <div className="select_group">
            <div className="select_group_item">
              <FormControl variant="outlined">
                <InputLabel id="labelCategory">Категория</InputLabel>
                <Select
                  labelId="labelCategory"
                  id="selectCategory"
                  value={categoryId}
                  onChange={handleChangeCategory}
                  label="Категория"
                  name="category"
                >
                  <MenuItem value={'0'}>Все</MenuItem>
                  {dataCategories?.map((el) => {
                    return !el.parentId ? (
                      <MenuItem key={el.id} value={el.id}>
                        {el.title}
                      </MenuItem>
                    ) : null
                  })}
                </Select>
              </FormControl>
            </div>

            {categoryId == '3' ? ( //телефоны
              <div className="select_group_item">
                <FormControl variant="outlined">
                  <InputLabel id="labelSubCategory">Подкатегория</InputLabel>
                  <Select
                    labelId="labelSubCategory"
                    id="selectSubCategory"
                    value={subCategoryId}
                    onChange={handleChangeSubCategory}
                    label="Подкатегория"
                    name="subCategory"
                  >
                    <MenuItem value={'all'}>Все</MenuItem>
                    {dataCategories?.map((el) => {
                      return el.parentId ? (
                        <MenuItem key={el.id} value={el.id}>
                          {el.title}
                        </MenuItem>
                      ) : null
                    })}
                  </Select>
                </FormControl>
              </div>
            ) : null}
          </div>
          <div className="buttons_group">
            <div>
              <Button
                variant="outlined"
                disabled={categoryId === '0'}
                onClick={() => setCreateMode(true)}
              >
                Добавить услугу
              </Button>
              <Button
                disabled={!isEditMode && !isCreateMode}
                variant="outlined"
                onClick={() => {
                  setEditMode(false)
                  setCreateMode(false)
                }}
              >
                Отменить
              </Button>
            </div>
          </div>
        </div>
      ) : null}

      {categoryId !== 'all' && isCreateMode ? (
        <CreateItemPriceList
          refetch={refetch}
          setCreateMode={() => setCreateMode(false)}
          category={subCategoryId === 'all' ? Number(categoryId) : Number(subCategoryId)}
        />
      ) : null}

      <div className="table">
        <div className="table_head">
          <div className="table_head_title">
            <p>Услуга</p>
          </div>
          <div className="table_head_title">
            <p>Цена от</p>
          </div>
          <div className="table_head_title">
            <p>Цена до</p>
          </div>
          <div className="table_head_title">
            <p>Действия</p>
          </div>
        </div>
        <div className="table_body">
          {isLoading ? (
            <div className={'loader'}>Загрузка...</div>
          ) : (
            priceListData?.map((item, index) =>
              isEditMode && editItemPriceListIndex === index ? (
                <div className="table_body_item" key={item.id}>
                  <input
                    required={true}
                    type="text"
                    defaultValue={item.name}
                    onChange={(e) => handleNameChange(index, e)}
                  />
                  <input
                    required={true}
                    type="number"
                    defaultValue={item.priceFrom}
                    onChange={(e) => handlePriceFromChange(index, e)}
                  />
                  <input
                    required={true}
                    type="number"
                    defaultValue={item.priceTo}
                    onChange={(e) => handlePriceToChange(index, e)}
                  />
                  <div>
                    <Button onClick={handleSubmitItemPriceList}>
                      <Icon color="primary">save</Icon>
                    </Button>
                    <Button onClick={handleDeleteItemPriceList}>
                      <Icon color="primary">delete</Icon>
                    </Button>
                  </div>
                </div>
              ) : (
                <div className="table_body_item" key={item.id}>
                  <div className="table_body_item_title">{item.name}</div>
                  <div className="table_body_item_priceFrom">{item.priceFrom}</div>
                  <div className="table_body_item_priceTo">{item.priceTo}</div>
                  <Button
                    onClick={() => {
                      setEditMode(true)
                      setEditItemPriceListIndex(index)
                      setEditItemPriceList(item)
                    }}
                  >
                    <Icon color="primary">edit</Icon>
                  </Button>
                </div>
              )
            )
          )}
        </div>
      </div>
    </Page>
  )
}
