import { injectable } from 'tsyringe'
import config from 'config'
import Analytics from 'utils/Analytics'
import api from 'utils/api'
import urljoin from 'url-join'
import { IFilial } from 'modules/filial/services/filial.service'

export interface IPaginationParams {
  page?: number
  limit?: number
}

export interface IRepair {
  id: number
  fullName: string
  phone: string
  comment?: string
  filial?: IFilial
  createdAt: string
}

export interface IResponseRepair {
  data: IRepair[] | []
  pageCurrent: number
  total: number
  pageCount: number
  message: number
  code: number
}

export interface IRepairService {
  getRepairs: (paginationParams?: IPaginationParams) => Promise<IResponseRepair | undefined>
}

@injectable()
class RepairService implements IRepairService {
  readonly config = {
    urlApi: urljoin(config.API_URL, 'repair'),
  }

  getRepairs = async (
    paginationParams?: IPaginationParams
  ): Promise<IResponseRepair | undefined> => {
    try {
      let paginationUrlStr = ''
      const page = paginationParams?.page
      const limit = paginationParams?.limit
      if (limit || page) {
        paginationUrlStr = '?'
        paginationUrlStr += page ? `page=${page}&` : ''
        paginationUrlStr += limit ? `limit=${limit}` : ''
      }

      const res = await api.get(urljoin(this.config.urlApi, 'all', paginationUrlStr))
      return res.data
    } catch (e) {
      Analytics.error(`Не удалость получить информацию о категории ${e}`)
    }
  }
}

export default RepairService
