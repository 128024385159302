import { injectable } from 'tsyringe'
import config from 'config'
import Analytics from 'utils/Analytics'
import api from 'utils/api'
import urljoin from 'url-join'

export interface IItemPriceList {
  id: number
  name: string
  priceFrom: number
  priceTo: number
  category: number
}

export type ICreateItemPriceList = Omit<IItemPriceList, 'id'>

export interface IPriceListService {
  getPriceListByCategoryId: (categoryId: number) => Promise<IItemPriceList[] | undefined>
  getPriceList: () => Promise<IItemPriceList[] | undefined>
  saveItemPriceList: (
    itemPriceList: IItemPriceList
  ) => Promise<Pick<IItemPriceList, 'id'> | undefined>
  createItemPriceList: (
    itemPriceList: ICreateItemPriceList
  ) => Promise<Pick<IItemPriceList, 'id'> | undefined>
  deleteItemPriceList: (
    itemPriceList: IItemPriceList
  ) => Promise<Pick<IItemPriceList, 'id'> | undefined>
}

@injectable()
class PriceListService implements IPriceListService {
  readonly config = {
    urlApi: urljoin(config.API_URL, 'priceList'),
  }

  getPriceList = async (): Promise<IItemPriceList[] | undefined> => {
    try {
      const res = await api.get(urljoin(this.config.urlApi, 'all'))
      return res.data.data
    } catch (e) {
      Analytics.error(`Не удалость получить информацию о категории ${e}`)
    }
  }

  getPriceListByCategoryId = async (categoryId: number): Promise<IItemPriceList[] | undefined> => {
    try {
      const res = await api.post(urljoin(this.config.urlApi, 'byCategoryId'), { categoryId })
      return res.data.data
    } catch (e) {
      Analytics.error(`Не удалость получить информацию о категории ${e}`)
    }
  }

  saveItemPriceList = async (
    itemPriceList: IItemPriceList
  ): Promise<Pick<IItemPriceList, 'id'> | undefined> => {
    try {
      const res = await api.put(
        urljoin(this.config.urlApi, String(itemPriceList.id)),
        itemPriceList
      )
      return res.data.data
    } catch (e) {
      Analytics.error(`Не удалость получить информацию о категории ${e}`)
    }
  }

  createItemPriceList = async (
    itemPriceList: ICreateItemPriceList
  ): Promise<Pick<IItemPriceList, 'id'> | undefined> => {
    try {
      const res = await api.post(urljoin(this.config.urlApi), itemPriceList)
      return res.data.data
    } catch (e) {
      Analytics.error(`Не удалость получить информацию о категории ${e}`)
    }
  }

  deleteItemPriceList = async (
    itemPriceList: IItemPriceList
  ): Promise<Pick<IItemPriceList, 'id'> | undefined> => {
    try {
      const res = await api.delete(urljoin(this.config.urlApi, String(itemPriceList.id)))
      return res.data.data
    } catch (e) {
      Analytics.error(`Не удалость получить информацию о категории ${e}`)
    }
  }
}

export default PriceListService
