import { injectable } from 'tsyringe'
import config from 'config'
import Analytics from 'utils/Analytics'
import api from 'utils/api'
import urljoin from 'url-join'

export interface ICity {
  id: number
  name: string
  code: string
}

export interface ICityService {
  getCities: () => Promise<ICity[] | undefined>
}

@injectable()
class CityService implements ICityService {
  readonly config = {
    urlApi: urljoin(config.API_URL, 'city'),
  }

  getCities = async (): Promise<ICity[] | undefined> => {
    try {
      const res = await api.get(urljoin(this.config.urlApi, 'all'))
      return res.data.data
    } catch (e) {
      Analytics.error(`Не удалость получить информацию о юзере ${e}`)
    }
  }
}

export default CityService
