import React, { useState } from 'react'
import { Button, Icon } from '@material-ui/core'
import { ICreateItemPriceList } from 'modules/priceList/services/priceList.service'
import { useModules } from 'modules/ModuleFactory'
import { useMutation } from 'react-query'
import 'pages/PriceList/components/createItemPriceList.sass'
import { toast } from 'react-toastify'

export const createItemPriceListDefault: ICreateItemPriceList = {
  name: '',
  priceFrom: 0,
  priceTo: 0,
  category: 0,
}

export default function CreateItemPriceList({
  category,
  refetch,
  setCreateMode,
}: {
  category: number
  refetch: any
  setCreateMode: any
}): JSX.Element {
  const { priceListModule } = useModules()

  const mutation = useMutation(async (createItemPriceList: ICreateItemPriceList) => {
    const res = await priceListModule.createItemPriceList(createItemPriceList)
    if (res) toast.success('Новая услуга успешно добавлена')
    await refetch()
  })

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault()
    mutation.mutate(createItemPriceList)
    setCreateMode()
  }

  const [createItemPriceList, setCreateItemPriceList] = useState<ICreateItemPriceList>({
    ...createItemPriceListDefault,
    category,
  })

  const handleNameChange = (event: React.FormEvent<HTMLInputElement>): void => {
    setCreateItemPriceList({ ...createItemPriceList, name: event.currentTarget.value })
  }

  const handlePriceFromChange = (event: React.FormEvent<HTMLInputElement>): void => {
    setCreateItemPriceList({ ...createItemPriceList, priceFrom: Number(event.currentTarget.value) })
  }

  const handlePriceToChange = (event: React.FormEvent<HTMLInputElement>): void => {
    setCreateItemPriceList({ ...createItemPriceList, priceTo: Number(event.currentTarget.value) })
  }

  return (
    <div>
      <form className="createItemPriceListForm" onSubmit={handleSubmit}>
        <input
          placeholder={'Наименование услуги'}
          required={true}
          type="text"
          onChange={handleNameChange}
        />
        <input
          required={true}
          type="number"
          placeholder={'Цена от'}
          onChange={handlePriceFromChange}
        />
        <input
          required={true}
          placeholder={'Цена до'}
          type="number"
          onChange={handlePriceToChange}
        />
        <div>
          <Button type={'submit'}>
            <Icon color="primary">save</Icon>
          </Button>
        </div>
      </form>
    </div>
  )
}
