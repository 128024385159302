import React from 'react'
import { setPage } from 'config/history'
import Logo from 'assets/logo.svg'
import { Button } from '@material-ui/core'
import { useAuth } from 'context/authProvider'

export const Header: React.FC = () => {
  const { isAuthorized, logout } = useAuth()

  const handleExitLKButton = () => {
    logout()
  }

  const actionButton: JSX.Element = isAuthorized ? (
    <div>
      <Button onClick={handleExitLKButton} variant="outlined">
        Выйти
      </Button>
    </div>
  ) : (
    <></>
  )

  const menu: JSX.Element = isAuthorized ? (
    <div className="nav">
      <button onClick={() => setPage('priceList')} className="nav_link">
        Прайс листы <div className="underline" />
      </button>
      <button onClick={() => setPage('requests')} className="nav_link">
        Заявки <div className="underline" />
      </button>
      <button onClick={() => setPage('filials')} className="nav_link">
        Филиалы <div className="underline" />
      </button>
    </div>
  ) : (
    <></>
  )

  return (
    <header>
      <div className="container">
        <div className="header_wrapper">
          <div className="logo">
            <img src={Logo} alt="" />
          </div>
          {menu}
          {actionButton}
        </div>
      </div>
    </header>
  )
}
