import React, { useState } from 'react'
import { Page } from 'components/Page/Page'
import TableRequests from 'pages/Requests/components/TableRequests'
import { useModules } from 'modules/ModuleFactory'
import { useQuery } from 'react-query'
import { IPaginationParams } from 'modules/repair/services/repair.service'

export default function Requests(): JSX.Element {
  const { repairModule } = useModules()
  const [filter, setFilter] = useState<IPaginationParams>({ limit: 50 })

  const { data: dataRepairs, isLoading } = useQuery(['repairs', filter], async () => {
    return await repairModule.getRepairs(filter)
  })

  return (
    <Page title={'Заявки'}>
      <TableRequests isLoading={isLoading} repairs={dataRepairs} setFilter={setFilter} />
    </Page>
  )
}
