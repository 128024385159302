import decode from 'jwt-decode'
import Analytics from 'utils/Analytics'
import { injectable } from 'tsyringe'
import urljoin from 'url-join'
import config from 'config'

export interface IRole {
  id: string
  name: string
}

export interface IPayload {
  id: string
  userId: string
  uuid: string
  roles?: IRole[]
}

export interface IToken {
  id: number
  userId: number
  refreshToken: string
  accessToken: string
  createDate: Date
  uptime: Date
}

export interface ITokenService {
  STORAGE_KEY: string
  getBasicToken: () => Promise<string | undefined>
  refreshToken: () => Promise<IToken | undefined>
  isExpired: (token: string) => Promise<boolean>
  getExpiredAccessToken: (token: string) => Promise<number>
  logout: () => Promise<void>
  getPayload: (token: IToken | undefined) => Promise<IPayload | undefined>
  autoCheckToken: (token: IToken | undefined) => Promise<IToken | undefined>
  getAccessToken: () => Promise<string>
  getRefreshToken: () => Promise<string>
  getToken: () => Promise<IToken | undefined>
  setToken: (token: IToken, remember?: boolean) => Promise<IToken>
  setBasicToken: (token: string) => Promise<string>
}

@injectable()
class TokenService implements ITokenService {
  readonly STORAGE_KEY = '__LK_FRONT_token__'
  readonly config = {
    urlApi: config.API_URL,
  }

  isExpired = async (token: string): Promise<boolean> => {
    const decodeToken: { exp: number } = decode(token)
    return decodeToken.exp * 1000 < Date.now()
  }

  getBasicToken = async (): Promise<string | undefined> => {
    const token = await localStorage.getItem(this.STORAGE_KEY)
    if (token) {
      return token
    } else {
      Analytics.info(`accessToken не найден`)
      await this.logout()
      return
    }
  }

  // Возвращает через сколько + 1сек, истечёт accessToken в милисекундах, если уже истёк то 10 сек
  getExpiredAccessToken = async (token: string): Promise<number> => {
    const decodeToken: { exp: number } = decode(token)
    const expIn = decodeToken.exp * 1000 - Date.now()
    return expIn > 0 ? expIn + 1000 : 10000
  }

  autoCheckToken = async (token: IToken | undefined): Promise<IToken | undefined> => {
    try {
      if (token && (await this.isExpired(token.accessToken))) {
        if (await this.isExpired(token.refreshToken)) {
          await this.logout()
          return
        }
        const newToken = await this.refreshToken()
        return newToken ? await this.setToken(newToken) : undefined
      }
      return token
    } catch (e) {
      Analytics.error(`Не удалось проверить токен ${e}`)
    }
  }

  refreshToken = async (): Promise<IToken | undefined> => {
    try {
      const token = await this.getToken()
      const res = await fetch(urljoin(this.config.urlApi, 'auth', 'refreshToken'), {
        method: 'POST',
        headers: {
          'Content-Type': `application/json`,
        },
        body: JSON.stringify({ token }),
      })

      const data = await res.json()
      if (res.status === 200 || res.status === 201) {
        return data
      }
      await this.logout()
    } catch (e) {
      Analytics.error(`Не удалость обновить токен ${e}`)
    }
  }

  logout = async (): Promise<void> => {
    try {
      localStorage.removeItem(this.STORAGE_KEY)
      sessionStorage.removeItem(this.STORAGE_KEY)
    } catch (e) {
      Analytics.error(`Не удалость выйти из системы ${e}`)
    }
  }

  getPayload = async (token: IToken | undefined): Promise<IPayload | undefined> => {
    try {
      if (token && token.accessToken) {
        const payload = decode<IPayload>(token.accessToken)
        if (payload) return payload
      }
    } catch (e) {
      Analytics.error(`Не удалость получить информацию о юзере ${e}`)
    }
  }

  getAccessToken = async (): Promise<string> => {
    let token = await this.getToken()
    if (token && token.accessToken) {
      token = await this.autoCheckToken(token)
      return token ? `${token.accessToken}` : ''
    } else {
      Analytics.info(`accessToken не найден`)
      return ''
    }
  }

  getRefreshToken = async (): Promise<string> => {
    const token = await this.getToken()
    if (token && token.refreshToken) {
      return `${token.refreshToken}`
    } else {
      Analytics.info(`refreshToken не найден`)
      return ''
    }
  }

  getToken = async (): Promise<IToken | undefined> => {
    const tokenString =
      localStorage.getItem(this.STORAGE_KEY) || sessionStorage.getItem(this.STORAGE_KEY)
    const token: IToken = tokenString ? JSON.parse(tokenString) : false
    if (token) {
      return token
    } else {
      Analytics.info(`Token не найден`)
    }
  }

  setToken = async (token: IToken, remember = true): Promise<IToken> => {
    const tokenString = JSON.stringify(token)
    remember
      ? localStorage.setItem(this.STORAGE_KEY, tokenString)
      : sessionStorage.setItem(this.STORAGE_KEY, tokenString)

    return token
  }

  setBasicToken = async (token: string): Promise<string> => {
    localStorage.setItem(this.STORAGE_KEY, token)
    return token
  }
}

export default TokenService
