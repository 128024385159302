import { IUserInfo } from 'modules/user/services/user.service'
import React, { createContext, useMemo, useContext, useCallback, useEffect, useState } from 'react'
import { useServices } from 'modules/ServiceFactory'
import ROUTES from 'config/routes'

interface IAuthContext {
  isAuthorized: boolean
  userInfo?: IUserInfo
  logout: () => void
}

export interface IAuthProviderProps {
  children?: React.ReactNode
}

export const AuthContext = createContext<IAuthContext>({
  isAuthorized: false,
  userInfo: {
    phone: '8999999999',
    middleName: '',
    lastName: 'Иван',
    firstName: 'Иван',
  },
  logout: () => undefined,
})

export const AuthProvider = ({ children }: IAuthProviderProps): JSX.Element => {
  const { tokenService } = useServices()
  const [isAuth, setIsAuth] = useState<boolean>()

  const logout = useCallback(() => {
    const fetchData = async () => {
      await tokenService.logout()
      if (window.location.pathname !== ROUTES.LOGIN.path) {
        window.location.replace(ROUTES.LOGIN.path)
      }
    }

    fetchData()
  }, [tokenService])

  const auth = useMemo(() => {
    return {
      isAuthorized: !!isAuth,
      logout,
    }
  }, [isAuth, logout])

  const getIsAuth = useCallback(() => {
    const fetchData = async () => {
      const token = await tokenService.getBasicToken()

      if (!!token) {
        setIsAuth(!!token)
      } else {
        logout()
      }
    }

    fetchData()
  }, [logout, tokenService])

  useEffect(() => {
    getIsAuth()
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return <AuthContext.Provider value={{ ...auth }}>{children}</AuthContext.Provider>
}

export const useAuth = (): IAuthContext => {
  const context = useContext<IAuthContext>(AuthContext)
  if (context === undefined) {
    throw new Error(`useAuth должен использоваться внутри AuthProvider`)
  }
  return context
}

export default AuthProvider
