import { inject, injectable, registry } from 'tsyringe'
import FilialService, {
  IFilialService,
  IFilial,
  ICity,
} from 'modules/filial/services/filial.service'
import CityService, { ICityService } from 'modules/filial/services/city.service'

@injectable()
@registry([
  {
    token: 'filialService',
    useClass: FilialService,
  },
  {
    token: 'cityService',
    useClass: CityService,
  },
])
export class FilialModule {
  constructor(
    @inject('filialService') private filialService: IFilialService,
    @inject('cityService') private cityService: ICityService
  ) {}

  getCities = async (): Promise<ICity[] | undefined> => {
    return await this.cityService.getCities()
  }

  getFilials = async (): Promise<IFilial[] | undefined> => {
    return await this.filialService.getFilials()
  }

  getFilialById = async (id: number): Promise<IFilial | undefined> => {
    return await this.filialService.getFilialById(id)
  }

  saveFilial = async (filial: IFilial, filialId: string): Promise<Partial<IFilial> | undefined> => {
    return await this.filialService.saveFilial(filial, filialId)
  }

  deleteFilial = async (filialId: string): Promise<Partial<IFilial> | undefined> => {
    return await this.filialService.deleteFilial(filialId)
  }
}
