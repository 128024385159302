import { inject, injectable, registry } from 'tsyringe'
import RepairService, {
  IPaginationParams,
  IRepairService,
  IResponseRepair,
} from 'modules/repair/services/repair.service'

@injectable()
@registry([
  {
    token: 'repairService',
    useClass: RepairService,
  },
])
export class RepairModule {
  constructor(@inject('repairService') private repairService: IRepairService) {}

  getRepairs = async (
    paginationParams?: IPaginationParams
  ): Promise<IResponseRepair | undefined> => {
    return await this.repairService.getRepairs(paginationParams)
  }
}
