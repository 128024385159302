import React, { useState } from 'react'
import { DataGrid, GridColDef } from '@material-ui/data-grid'
import { IPaginationParams, IResponseRepair } from 'modules/repair/services/repair.service'
import moment from 'moment/moment'

const columns: GridColDef[] = [
  { field: 'id', headerName: '№', width: 100 },
  {
    field: 'fullName',
    headerName: 'ФИО',
    width: 240,
  },
  {
    field: 'phone',
    headerName: 'Телефон',
    type: 'number',
    headerAlign: 'left',
    align: 'center',
    width: 195,
  },
  {
    field: 'filial',
    headerName: 'Филиал',
    type: 'string',
    width: 240,
    valueFormatter: (params) => {
      if (!params.row.filial) return '-'
      const street = `ул. ${params.row.filial.street}`
      const build = params.row.filial.build ? `д. ${params.row.filial.build}` : ''
      const structure = params.row.filial.structure ? `ст. ${params.row.filial.structure}` : ''
      const corps = params.row.filial.corps ? `к. ${params.row.filial.corps}` : ''

      return `${street} ${build} ${structure} ${corps}`
    },
  },
  {
    field: 'createdAt',
    headerName: 'Дата заявки',
    type: 'dateTime',
    width: 180,
    valueFormatter: (params) =>
      moment(params.row.createdAt, 'DD-MM-YYYY HH:mm').format('DD.MM.YYYY HH:mm'),
  },
  {
    field: 'comment',
    headerName: 'Коментарий',
    sortable: false,
    minWidth: 200,
    width: 600,
    // eslint-disable-next-line react/display-name
    renderCell: (params) => {
      return <div style={{ overflow: 'auto', height: '55px' }}>{`${params.row.comment}`}</div>
    },
  },
]

const TableRequests = ({
  isLoading,
  repairs,
  setFilter,
}: {
  setFilter: (filter: IPaginationParams) => void
  isLoading: boolean
  repairs?: IResponseRepair
}): JSX.Element => {
  const [pageSize, setPageSize] = useState(50)
  //const [page, setPage] = useState(0)

  // const handlePageSizeChange = async (pageSize: number) => {
  //   setPageSize(pageSize)
  //   setFilter({ limit: pageSize, page: page })
  // }

  const handlePageChange = async (page: number) => {
    setFilter({ limit: 50, page: page + 1 })
  }

  return (
    <div className="dataGrid_wrapper" style={{ height: 'calc(100vh - 250px)' }}>
      <DataGrid
        loading={isLoading}
        rows={repairs?.data || []}
        columns={columns}
        disableSelectionOnClick
        disableColumnMenu
        showCellRightBorder
        paginationMode="server"
        rowsPerPageOptions={[50]}
        rowCount={repairs?.total}
        pageSize={pageSize}
        // onPageSizeChange={handlePageSizeChange}
        onPageChange={handlePageChange}
      />
    </div>
  )
}

export default TableRequests
