import React, { useState } from 'react'
import { setPage } from 'config/history'
import 'pages/Filial/filialCreate.sass'
import { Page } from 'components/Page/Page'
import TextField from '@material-ui/core/TextField'
import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Button from '@material-ui/core/Button'
import InputLabel from '@material-ui/core/InputLabel'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import { TimePicker } from '@material-ui/pickers'
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date'
import moment from 'moment'
import { Icon, RadioGroup, Radio } from '@material-ui/core'
import { IFilial } from 'modules/filial/services/filial.service'
import { useServices } from 'modules/ServiceFactory'
import { useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { TextMaskCustom } from 'components/TextMaskCustom/TextMaskCustom'

export const FilialCreate: React.FC = () => {
  const { cityId } = useParams<{ cityId: string }>()

  const { filialService } = useServices()
  const [fields, setFields] = useState<Partial<IFilial>>({
    cityId: Number(cityId),
    phone: '',
    receivingPoint: true,
    serviceCenter: false,
    aroundTheClock: false,
    workDayFrom: 'пн',
    workDayTo: 'пт',
    workTimeTo: moment('00:00:00', 'HH:mm:ss').format('HH:mm:ss'),
    workTimeFrom: moment('00:00:00', 'HH:mm:ss').format('HH:mm:ss'),
  })

  const handleSubmit = async (event: React.ChangeEvent<HTMLFormElement>) => {
    event.preventDefault()
    const res = await filialService.createFilial(fields as IFilial)
    if (res) toast.success('Филиал создан успешно')
    setPage('filials')
  }

  const handleChangeSelect = async (
    event: React.ChangeEvent<{ value: unknown; name?: string }>
  ) => {
    if (event.target.name) {
      setFields({ ...fields, [event.target.name]: event.target.value as string })
      return
    }
    console.error('У поля отсутствует name')
  }

  const handleChangeCheckbox = async (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.name && 'checked' in event.target) {
      setFields({ ...fields, [event.target.name]: event.target.checked })
      return
    }
    console.error('У поля отсутствует name или checked')
  }

  const handleChangeTypeFilial = async (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.value === 'receivingPoint') {
      setFields({ ...fields, receivingPoint: true, serviceCenter: false })
    } else {
      setFields({ ...fields, serviceCenter: true, receivingPoint: false })
    }
    return
  }

  const handleChangeDateTime = async (date: MaterialUiPickersDate, name: string) => {
    setFields({ ...fields, [name]: moment(date).format('HH:mm:ss') })
  }

  const handleChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.name) {
      setFields({ ...fields, [event.target.name]: event.target.value })
      return
    }
    console.error('У поля отсутствует name')
  }

  const handleChangePhone = async (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
    key: number
  ) => {
    const phones = fields?.phone?.split(',') || []
    phones[key] = event.target.value
    setFields({ ...fields, phone: phones.join(',') })
  }

  const handleAddPhone = async () => {
    setFields({ ...fields, phone: fields.phone + ',' })
  }

  const handleDeletePhone = async (key: number) => {
    const phones = fields?.phone?.split(',') || []
    const filterPhones = phones.filter((val, i) => i !== key)

    setFields({ ...fields, phone: filterPhones.join(',') })
  }

  return (
    <Page title={'Добавить филиал'}>
      <button className="buttonBack" onClick={() => setPage('filials')}>
        <Icon className="material-icons">arrow_back</Icon>Назад
      </button>

      <form onSubmit={handleSubmit}>
        <div className="new_branch_form_wrapper">
          <div className="form_row_address">
            <div className="row_title">
              <p>Адрес</p>
            </div>

            <div className="row_content">
              <div className="input_group">
                <TextField
                  id="outlined-basic"
                  required={true}
                  onChange={handleChange}
                  name="street"
                  label="Улица"
                  variant="outlined"
                />
              </div>
              <div className="input_group">
                <TextField
                  id="outlined-basic"
                  onChange={handleChange}
                  name="build"
                  label="Дом"
                  variant="outlined"
                />
              </div>
              <div className="input_group">
                <TextField
                  id="outlined-basic"
                  name="structure"
                  label="Строение"
                  onChange={handleChange}
                  variant="outlined"
                />
              </div>
              <div className="input_group">
                <TextField
                  id="outlined-basic"
                  onChange={handleChange}
                  name="corps"
                  label="Корпус"
                  variant="outlined"
                />
              </div>
            </div>
          </div>

          <div className="form_row_type">
            <div className="row_title">
              <p>Тип точки</p>
            </div>

            <div className="row_content">
              <FormControl component="fieldset">
                <RadioGroup
                  aria-label="gender"
                  name="typeFilial"
                  value={fields.receivingPoint ? 'receivingPoint' : 'serviceCenter'}
                  onChange={handleChangeTypeFilial}
                >
                  <FormControlLabel
                    value="receivingPoint"
                    control={<Radio />}
                    label="Точка приема"
                  />
                  <FormControlLabel
                    value="serviceCenter"
                    control={<Radio />}
                    label="Сервисный центр"
                  />
                </RadioGroup>
              </FormControl>
            </div>
          </div>

          <div className="form_row_phone">
            <div className="row_title">
              <p>Телефон</p>
              <Button variant="contained" size="large" color="primary" onClick={handleAddPhone}>
                Добавить
              </Button>
            </div>

            <div className="row_content">
              {fields?.phone?.split(',').map((el, i) => {
                return (
                  <div className="input_group" key={i}>
                    <TextMaskCustom
                      value={el}
                      required={true}
                      onChange={(event) => handleChangePhone(event, i)}
                      mask="+7 (999) 999-99-99"
                      maskPlaceholder={' '}
                    >
                      {() => (
                        <TextField
                          required={true}
                          id={'TextField'}
                          label="Телефон"
                          variant="outlined"
                          placeholder="+79093730202"
                        />
                      )}
                    </TextMaskCustom>
                    <Button onClick={() => handleDeletePhone(i)}>
                      <Icon color="primary">delete</Icon>
                    </Button>
                  </div>
                )
              })}
            </div>
          </div>

          <div className="form_row_schedule">
            <div className="row_title">
              <p>График работы</p>
            </div>

            <div className="row_content">
              <FormControl variant="outlined">
                <InputLabel id="labelWorkDayFrom">с</InputLabel>
                <Select
                  required={true}
                  labelId="labelWorkDayFrom"
                  id="selectWorkDayFrom"
                  value={fields?.workDayFrom || 'пн'}
                  onChange={handleChangeSelect}
                  label="от"
                  name="workDayFrom"
                >
                  <MenuItem value={'пн'}>пн</MenuItem>
                  <MenuItem value={'вт'}>вт</MenuItem>
                  <MenuItem value={'ср'}>ср</MenuItem>
                  <MenuItem value={'чт'}>чт</MenuItem>
                  <MenuItem value={'пт'}>пт</MenuItem>
                  <MenuItem value={'сб'}>сб</MenuItem>
                  <MenuItem value={'вс'}>вс</MenuItem>
                </Select>
              </FormControl>

              <p className="separator">-</p>

              <FormControl variant="outlined">
                <InputLabel id="labelWorkDayTo">по</InputLabel>
                <Select
                  onChange={handleChangeSelect}
                  required={true}
                  labelId="labelWorkDayTo"
                  id="selectWorkDayTo"
                  name="workDayTo"
                  value={fields?.workDayTo || 'пт'}
                  label="до"
                >
                  <MenuItem value={'пн'}>пн</MenuItem>
                  <MenuItem value={'вт'}>вт</MenuItem>
                  <MenuItem value={'ср'}>ср</MenuItem>
                  <MenuItem value={'чт'}>чт</MenuItem>
                  <MenuItem value={'пт'}>пт</MenuItem>
                  <MenuItem value={'сб'}>сб</MenuItem>
                  <MenuItem value={'вс'}>вс</MenuItem>
                </Select>
              </FormControl>

              <div className="time_input_group">
                <TimePicker
                  variant="inline"
                  inputVariant="outlined"
                  ampm={false}
                  label="с"
                  value={moment(fields.workTimeFrom, 'HH:mm:ss')}
                  onChange={(date, name = 'workTimeFrom') => handleChangeDateTime(date, name)}
                  name="workTimeFrom"
                />

                <p className="separator">-</p>

                <TimePicker
                  variant="inline"
                  inputVariant="outlined"
                  ampm={false}
                  label="до"
                  value={moment(fields.workTimeTo, 'HH:mm:ss')}
                  onChange={(date, name = 'workTimeTo') => handleChangeDateTime(date, name)}
                  name="workTimeTo"
                />
              </div>

              <div className="checkbox_input_group">
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={!!fields.aroundTheClock}
                      onChange={handleChangeCheckbox}
                      name="aroundTheClock"
                    />
                  }
                  label="Круглосуточно"
                />
              </div>
            </div>
          </div>

          <div className="form_row_latitude">
            <div className="row_title">
              <p>Широта</p>
            </div>

            <div className="row_content">
              <TextField
                onChange={handleChange}
                required={true}
                id="outlined-basic"
                name="latitude"
                label="Широта"
                variant="outlined"
                placeholder="44.32123456"
                value={fields.latitude}
              />
            </div>
          </div>

          <div className="form_row_longitude">
            <div className="row_title">
              <p>Долгота</p>
            </div>

            <div className="row_content">
              <TextField
                onChange={handleChange}
                required={true}
                id="outlined-basic"
                name="longitude"
                label="Долгота"
                variant="outlined"
                placeholder="44.32123456"
                value={fields.longitude}
              />
            </div>
          </div>

          <div className="form_row_name">
            <div className="row_title">
              <p>Название филиала</p>
            </div>

            <div className="row_content">
              <TextField
                id="outlined-basic"
                name="name"
                label="Название"
                variant="outlined"
                placeholder=""
                onChange={handleChange}
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="buttonSaveContainer">
            <Button variant="contained" type="submit" size="large" color="primary">
              Сохранить
            </Button>
          </div>
        </div>
      </form>
    </Page>
  )
}
