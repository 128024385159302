import { inject, injectable, registry } from 'tsyringe'
import CategoryService, {
  ICategoryService,
  ICategory,
} from 'modules/category/services/category.service'

@injectable()
@registry([
  {
    token: 'categoryService',
    useClass: CategoryService,
  },
])
export class CategoryModule {
  constructor(@inject('categoryService') private categoryService: ICategoryService) {}

  getCategories = async (): Promise<ICategory[] | undefined> => {
    return await this.categoryService.getCategories()
  }
}
